import { httpApi } from '@app/api/http.api';
import { AxiosResponse } from 'axios';

export interface ReportData {
  startDate: string;
  endDate: string;
  totalReservations: number;
  bookingRevenue: string;
  tax: string;
  totalLTPCollected: string;
  ciFee: string;
  totalRevenue: string;
  totalRefunds: string;
  netRevenue: string;
}

export interface ReportResponse {
  status: number;
  message: string;
  data: ReportData;
}

export const getReport = (params: { startDate: string; endDate: string }): Promise<ReportResponse> =>
  httpApi
    .get<any, AxiosResponse<ReportResponse>>('reservation/report', { params }) // Fix AxiosResponse type
    .then((response) => response.data); // Extract 'data' from AxiosResponse
