import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReport, ReportData } from '@app/api/reporting.api';

export interface ReportState {
  reportData: ReportData | null;
  loading: boolean;
  error: string | null;
}

const initialState: ReportState = {
  reportData: null,
  loading: false,
  error: null,
};

// Async Thunk to Fetch Report Data
export const fetchReport = createAsyncThunk('report/fetch', async (payload: { startDate: string; endDate: string }) => {
  const response = await getReport(payload);
  return response.data; // Extract the 'data' part from the API response
});

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReport.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchReport.fulfilled, (state, action) => {
      state.loading = false;
      state.reportData = action.payload; // action.payload is of type ReportData
    });
    builder.addCase(fetchReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Something went wrong';
    });
  },
});

export default reportSlice.reducer;
